<script>
import axios from "axios";
import { baseApiUrl, ungerboeckEnvironment } from "./environment";
import Multiselect from "@vueform/multiselect";

export default {
  name: "App",
  components: {
    Multiselect,
  },
  data() {
    return {
      eventAccountCode: "",
      eventId: "",
      eventFunctionId: "",
      hash: "",
      message: "",
      orderList: [],
      orders: [],
      tableList: [],
      tableStatuses: [],
      disableSave: true,
    };
  },
  mounted() {
    this.getUrlParams();
    this.getEventData();
  },
  methods: {
    arraysEqual(a, b) {
      return a.sort().join() == b.sort().join();
    },
    assignDistinctTableToOrder(order, tableNumber) {
      if (!order.currentTables.includes(tableNumber)) {
        order.currentTables.push(tableNumber);
      }
    },
    getNumberOfOrdersForTable(tableNumber) {
      return this.orders.filter((o) => o.currentTables.some((number) => number == tableNumber)).length;
    },
    getEventData() {
      const eventRequest = {
        EventId: this.eventId,
        EventAccountCode: this.eventAccountCode,
        EventFunctionId: this.eventFunctionId,
      };
      axios
        .post(baseApiUrl + "/GetEventTables", eventRequest)
        .then((response) => {
          this.hash = response.data.hash;
          const assignments = this.processAssignments(
            response.data.assignments
          );
          this.tableStatuses = response.data.tableStatuses;
          this.orders = assignments.orders;
          this.tableList = assignments.tables;
          this.orderList = this.orders.map((o) => o.number);
          this.disableSave = false;
          this.message = "Received latest changes.";
        })
        .catch((error) => {
          this.message = error.message;
          this.disableSave = true;
        });
    },
    getUpdatedOrders() {
      const updatedOrders = [];
      this.orders.forEach((o) => {
        if (!this.arraysEqual(o.currentTables, o.originalTables))
          updatedOrders.push({
            Number: o.number,
            Tables: o.currentTables.toString(),
          });
      });

      return updatedOrders;
    },
    getUpdatedTables() {
      return this.tableList
        .filter((t) => t.currentStatus != t.originalStatus)
        .map((t) => ({
          SequenceNumber: t.sequenceNumber,
          Status: t.currentStatus,
        }));
    },
    getUrlParams() {
      const searchUrl = new URL(window.location);
      this.eventId = searchUrl.searchParams.get("eventId");
      this.eventAccountCode = searchUrl.searchParams.get("eventAccountCode");
      this.eventFunctionId = searchUrl.searchParams.get("eventFunctionId");
    },
    processAssignments(data) {
      const assignments = { orders: [], tables: [] };
      assignments.tables = data.tables.map((t) => ({
        tableId: t.tableId,
        capacity: t.capacity,
        sequenceNumber: t.tableSequence,
        originalStatus: t.tableStatus,
        currentStatus: t.tableStatus,
      }));
      data.orders.forEach((o) => {
        const tables = o.tables.split(",").filter((t) => t !== "");
        assignments.orders.push({
          number: o.number,
          account: o.account,
          contact: o.contact,
          originalTables: structuredClone(tables),
          currentTables: tables,
        });
      });
      assignments.orders.sort((a, b) =>
        a.account.toLowerCase().localeCompare(b.account.toLowerCase())
      );

      return assignments;
    },
    removeItemFromArray(array, item) {
      const index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    updateEventTables() {
      this.message = "Updating orders and tables.";
      this.disableSave = true;
      const eventUpdate = {
        EventId: this.eventId,
        EventAccountCode: this.eventAccountCode,
        Hash: this.hash,
        Orders: this.getUpdatedOrders(),
        Tables: this.getUpdatedTables(),
      };
      axios
        .post(baseApiUrl + "/UpdateEventTables", eventUpdate)
        .then((response) => {
          this.message = response.data + " Getting latest changes.";
          this.getEventData();
        })
        .catch((error) => {
          this.message = error.message;
          this.disableSave = false;
        });
    },
    updateOrders(tableNumber, orders) {
      this.orders.forEach((o) => {
        if (orders.includes(o.number)) {
          this.assignDistinctTableToOrder(o, tableNumber);
        } else if (o.currentTables.includes(tableNumber)) {
          this.removeItemFromArray(o.currentTables, tableNumber);
        }
      });
    },
  },
  computed: {
    tables() {
      const tables = [];
      this.tableList.forEach((t) => {
        const orders = this.orders
          .filter((o) => o.currentTables.some((a) => a == t.tableId))
          .map((o) => o.number);
        tables.push({ table: t, orders: orders });
      });

      return tables;
    },
    tableIds() {
      return this.tableList.map((t) => {
        const ordersOnTable = this.getNumberOfOrdersForTable(t.tableId);
        return {
          value: t.tableId,
          label: `${t.tableId} [${ordersOnTable}]`
        }
      });
    },
    ungerboeckEnviroment() {
      return ungerboeckEnvironment;
    },
    bannerClasses() {
      const suffix = ungerboeckEnvironment.toLowerCase();
        return `banner banner-${suffix}`
    },
  },
};
</script>

<template>
  <div :class="this.bannerClasses">
    <p>{{ ungerboeckEnviroment }}</p>
  </div>
  <div class="row">
    <div class="column" style="overflow-y: auto; overflow-x: hidden">
      <table>
        <tr>
          <th>Table</th>
          <th>Capacity</th>
          <th>Status</th>
          <th>Order(s)</th>
        </tr>
        <tr v-for="table in tables" :key="table">
          <td>{{ table.table.tableId }}</td>
          <td>{{ table.table.capacity }}</td>
          <td  class="stored-items">
            <select v-model="table.table.currentStatus" class="single-select">
              <option value=" ">None</option>
              <option
                v-for="(label, value) in tableStatuses"
                :key="value"
                :value="value"
              >
                {{ label }}
              </option>
            </select>
          </td>
          <td class="stored-items">
            <Multiselect
              @input="updateOrders(table.table.tableId, $event)"
              v-model="table.orders"
              mode="tags"
              placeholder="Begin typing to assign order"
              :options="orderList"
              :searchable="true"
              :canClear="false"
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="column" style="overflow-y: auto; overflow-x: hidden">
      <table>
        <tr>
          <th>Order</th>
          <th class="account-column">Account</th>
          <th class="contact-column">Contact</th>
          <th>Table(s)</th>
        </tr>
        <tr v-for="order in orders" :key="order">
          <td>{{ order.number }}</td>
          <td>{{ order.account }}</td>
          <td>{{ order.contact }}</td>
          <td class="stored-items">
            <Multiselect
              v-model="order.currentTables"
              mode="tags"
              placeholder="Begin typing to assign table"
              :options="tableIds"
              :searchable="true"
              :canClear="false"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div>
    <button @click="updateEventTables" :disabled="disableSave">Save</button>
  </div>
  <h3>{{ message }}</h3>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<style src="../public/site.css"></style>
